var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "b-row",
          { staticClass: "mb-4" },
          [
            _c(
              "b-col",
              [
                _c(
                  "b-nav",
                  { staticClass: "nav-section", attrs: { tabs: "" } },
                  [
                    _c(
                      "b-nav-item",
                      {
                        attrs: { active: _vm.bucket_option == 0 },
                        on: {
                          click: function ($event) {
                            _vm.bucket_option = 0
                          },
                        },
                      },
                      [_vm._v("All")]
                    ),
                    _c(
                      "b-nav-item",
                      {
                        attrs: { active: _vm.bucket_option == 1 },
                        on: {
                          click: function ($event) {
                            _vm.bucket_option = 1
                          },
                        },
                      },
                      [_vm._v("Issues")]
                    ),
                    _c(
                      "b-nav-item",
                      {
                        attrs: { active: _vm.bucket_option == 2 },
                        on: {
                          click: function ($event) {
                            _vm.bucket_option = 2
                          },
                        },
                      },
                      [_vm._v("Closed")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("b-col", { attrs: { sm: "12", md: 7 } }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "search-group" },
                    [
                      _c("b-input-group-prepend", [
                        _c(
                          "span",
                          { staticClass: "input-group-text" },
                          [_c("b-icon", { attrs: { icon: "search" } })],
                          1
                        ),
                      ]),
                      _c("b-form-input", {
                        staticClass: "claim-search-input pl-1",
                        attrs: {
                          type: "search",
                          placeholder:
                            "Try typing a provider or patient's name",
                        },
                        on: {
                          keyup: _vm.debounceClaims,
                          search: _vm.debounceClaims,
                        },
                        model: {
                          value: _vm.search.terms,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "terms", $$v)
                          },
                          expression: "search.terms",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c("span", { staticClass: "divider" }),
                          _c(
                            "div",
                            [
                              _c(
                                "b-dropdown",
                                {
                                  staticClass: "dropdown flag-dropdown",
                                  scopedSlots: _vm._u([
                                    {
                                      key: "button-content",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.flagInputLabel) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "b-dropdown-form",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "20px",
                                            "font-weight": "300",
                                            "margin-bottom": "15px",
                                          },
                                        },
                                        [_vm._v("Substatuses")]
                                      ),
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "mb-2",
                                          on: {
                                            change: _vm.selectAllSubstatuses,
                                          },
                                          model: {
                                            value: _vm.doSelectAllSubstatuses,
                                            callback: function ($$v) {
                                              _vm.doSelectAllSubstatuses = $$v
                                            },
                                            expression:
                                              "doSelectAllSubstatuses",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "avo-text-bold" },
                                            [_vm._v("Select/Deselect All")]
                                          ),
                                        ]
                                      ),
                                      _c("b-form-checkbox-group", {
                                        attrs: { options: _vm.substatuses },
                                        on: { change: _vm.debounceClaims },
                                        model: {
                                          value: _vm.search.substatuses,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.search,
                                              "substatuses",
                                              $$v
                                            )
                                          },
                                          expression: "search.substatuses",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "divider" }),
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "dropdown filter-dropdown",
                              attrs: { boundary: "window" },
                              scopedSlots: _vm._u([
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c("b-icon-funnel", {
                                        staticClass: "mr-2",
                                      }),
                                      _vm._v(
                                        _vm._s(_vm.filterInputLabel) + " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "b-dropdown-form",
                                {
                                  staticClass:
                                    "d-flex flex-column align-items-start",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "20px",
                                        "font-weight": "300",
                                      },
                                    },
                                    [_vm._v("Filters")]
                                  ),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Age of Claim"),
                                  ]),
                                  _c("b-form-checkbox-group", {
                                    staticClass: "claim-age-btn-group",
                                    attrs: {
                                      options: _vm.age_ranges,
                                      buttons: "",
                                      "button-variant": "outline-primary",
                                      id: "search-ages",
                                    },
                                    model: {
                                      value: _vm.search.ages,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "ages", $$v)
                                      },
                                      expression: "search.ages",
                                    },
                                  }),
                                  _c("br"),
                                  _vm.bucket_option != 2
                                    ? _c(
                                        "div",
                                        { staticClass: "selecter-label" },
                                        [_vm._v("Status of Claim")]
                                      )
                                    : _vm._e(),
                                  _vm.bucket_option != 2
                                    ? _c("multiselect", {
                                        attrs: {
                                          "hide-selected": true,
                                          options: _vm.states,
                                          "track-by": "state",
                                          label: "label",
                                          multiple: true,
                                          "show-labels": false,
                                          placeholder: "-- Select an Option --",
                                        },
                                        model: {
                                          value: _vm.search.states,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.search, "states", $$v)
                                          },
                                          expression: "search.states",
                                        },
                                      })
                                    : _vm._e(),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Assigned"),
                                  ]),
                                  _c("worker-multiselect", {
                                    attrs: { multiple: true },
                                    model: {
                                      value: _vm.search.workers,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "workers", $$v)
                                      },
                                      expression: "search.workers",
                                    },
                                  }),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Practice"),
                                  ]),
                                  _c("practice-multiselect", {
                                    attrs: { multiple: true },
                                    model: {
                                      value: _vm.search.practices,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "practices", $$v)
                                      },
                                      expression: "search.practices",
                                    },
                                  }),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Provider"),
                                  ]),
                                  _c("provider-multiselect", {
                                    attrs: { multiple: true },
                                    model: {
                                      value: _vm.search.providers,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "providers", $$v)
                                      },
                                      expression: "search.providers",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end mt-4",
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "avo-basic-btn",
                                          attrs: { variant: "outline-primary" },
                                          on: { click: _vm.clearFilters },
                                        },
                                        [_vm._v("Clear Filters")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "avo-primary-btn ml-3",
                                          attrs: { variant: "outline-primary" },
                                          on: { click: _vm.searchPayerClaims },
                                        },
                                        [_vm._v("Apply")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm.claims
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "avo-table-lrg-wrapper" },
              [
                _c("b-table", {
                  ref: "table",
                  staticClass: "avo-table-lrg",
                  attrs: {
                    id: "payer-claims-table",
                    "sort-by": _vm.sortBy,
                    "sort-desc": _vm.sortDesc,
                    "per-page": _vm.perPage,
                    "current-page": _vm.currentPage,
                    items: _vm.getPayerClaims,
                    fields: _vm.claimsFields,
                    busy: _vm.tableLoading,
                    selectable: true,
                    "select-mode": "single",
                    "primary-key": "claim_id",
                    "show-empty": "",
                    emptyText: "No claims match the selected filters",
                    striped: "",
                  },
                  on: {
                    "update:sortBy": function ($event) {
                      _vm.sortBy = $event
                    },
                    "update:sort-by": function ($event) {
                      _vm.sortBy = $event
                    },
                    "update:sortDesc": function ($event) {
                      _vm.sortDesc = $event
                    },
                    "update:sort-desc": function ($event) {
                      _vm.sortDesc = $event
                    },
                    "row-clicked": _vm.onSelect,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "table-busy",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center text-info my-2" },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle mr-2",
                                }),
                                _c("strong", [
                                  _vm._v("Fetching claims for you ..."),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "cell(claim_id)",
                        fn: function (data) {
                          return [
                            _c("div", [
                              _c("strong", [_vm._v(_vm._s(data.value))]),
                            ]),
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center mb-1",
                                },
                                [
                                  _c("div", {
                                    class: [
                                      "status-icon",
                                      data.item.state.short,
                                    ],
                                  }),
                                  _c("div", { staticClass: "claim-state" }, [
                                    _vm._v(_vm._s(data.item.state.label)),
                                  ]),
                                ]
                              ),
                              data.item.substatus_1
                                ? _c(
                                    "div",
                                    { staticClass: "avo-text-light-small" },
                                    [
                                      _vm._v(
                                        " - " +
                                          _vm._s(
                                            _vm.substatuses[
                                              data.item.substatus_1
                                            ]
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              data.item.substatus_2
                                ? _c(
                                    "div",
                                    { staticClass: "avo-text-light-small" },
                                    [
                                      _vm._v(
                                        " - " +
                                          _vm._s(
                                            _vm.substatuses[
                                              data.item.substatus_2
                                            ]
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              data.item.substatus_3
                                ? _c(
                                    "div",
                                    { staticClass: "avo-text-light-small" },
                                    [
                                      _vm._v(
                                        " - " +
                                          _vm._s(
                                            _vm.substatuses[
                                              data.item.substatus_3
                                            ]
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "cell(patient)",
                        fn: function (data) {
                          return [
                            _c("div", [
                              _vm._v(_vm._s(data.item.patient_name) + " "),
                            ]),
                            data.item.patient_id
                              ? _c("div", { staticClass: "subtext" }, [
                                  _vm._v(
                                    "Patient ID: " +
                                      _vm._s(data.item.patient_id)
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "cell(assigned)",
                        fn: function (data) {
                          return [
                            _vm.bucket_option != 1
                              ? _c("div", [
                                  data.item.worker_name
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(data.item.worker_name) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [_vm._v(" -- ")]),
                                ])
                              : _c("div", [
                                  data.item.issues_worker_name
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              data.item.issues_worker_name
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [_vm._v(" -- ")]),
                                ]),
                          ]
                        },
                      },
                      {
                        key: "cell(payer)",
                        fn: function (data) {
                          return [
                            _c("div", [_vm._v(_vm._s(data.item.payer_name))]),
                          ]
                        },
                      },
                      {
                        key: "cell(practice)",
                        fn: function (data) {
                          return [
                            _c("div", [
                              _vm._v(_vm._s(data.item.practice.name || "--")),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "cell(provider)",
                        fn: function (data) {
                          return [
                            _c("div", [
                              _vm._v(" " + _vm._s(data.item.provider_name)),
                              data.value.suffix
                                ? _c("span", [
                                    _vm._v(", " + _vm._s(data.value.suffix)),
                                  ])
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              { staticClass: "subtext d-flex flex-column" },
                              [
                                data.item.pracitce_location
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(data.item.practice_location.name)
                                      ),
                                    ])
                                  : _vm._e(),
                                data.item.practice_location_address
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatAddress(
                                            data.item.practice_location_address
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(age)",
                        fn: function (data) {
                          return [
                            _c("div", [_vm._v(_vm._s(data.value) + " Days")]),
                          ]
                        },
                      },
                      {
                        key: "cell(balance)",
                        fn: function (data) {
                          return [
                            _c("div", [
                              _vm._v("$" + _vm._s(data.item.balance)),
                            ]),
                            _c("div", { staticClass: "subtext" }, [
                              _c("div", [
                                _vm._v("$" + _vm._s(data.item.invoiced)),
                              ]),
                              _c("div", [_vm._v("Claim Amount")]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "cell(timely_days_left)",
                        fn: function (data) {
                          return [
                            data.value
                              ? _c("span", [
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        "timely-filing-icon",
                                        _vm.timelyFilingClass(data.value),
                                      ],
                                    },
                                    [_vm._v(" " + _vm._s(data.value) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        color: "var(--gray)",
                                      },
                                    },
                                    [_vm._v("Days Left")]
                                  ),
                                ])
                              : _c("span", [_vm._v(" -- ")]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1972769221
                  ),
                }),
              ],
              1
            ),
            _vm.claims.length > 0
              ? _c("b-pagination", {
                  attrs: {
                    align: "center",
                    "total-rows": _vm.rows,
                    "per-page": _vm.perPage,
                    "aria-controls": "claims-table",
                  },
                  model: {
                    value: _vm.currentPage,
                    callback: function ($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage",
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }