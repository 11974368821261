var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "pr-0", attrs: { cols: "7" } },
            [
              _c(
                "b-card",
                { staticClass: "avo-info-card mb-4" },
                [
                  _c("div", { staticClass: "avo-header-text mb-4" }, [
                    _vm._v("Payer Details"),
                  ]),
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c("b-col", [
                        _c("div", { staticClass: "label-text" }, [
                          _vm._v("Payer EDI"),
                        ]),
                        _c("div", { staticClass: "data-text" }, [
                          _vm._v(_vm._s(_vm.payer.edi || "-")),
                        ]),
                      ]),
                      _c("b-col", [
                        _c("div", { staticClass: "label-text" }, [
                          _vm._v("Avo ID"),
                        ]),
                        _c("div", { staticClass: "data-text" }, [
                          _vm._v(_vm._s(_vm.payer.payer_id || "-")),
                        ]),
                      ]),
                      _c("b-col", [
                        _c("div", { staticClass: "label-text" }, [
                          _vm._v("Address"),
                        ]),
                        _c("div", { staticClass: "data-text" }, [
                          _vm._v(_vm._s(_vm.payer.full_address || "-")),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c("b-col", [
                        _c("div", { staticClass: "label-text" }, [
                          _vm._v("Phone"),
                        ]),
                        _c("div", { staticClass: "data-text" }, [
                          _vm._v(_vm._s(_vm.payer.telephone || "-")),
                        ]),
                      ]),
                      _c("b-col", [
                        _c("div", { staticClass: "label-text" }, [
                          _vm._v("Fax"),
                        ]),
                        _c("div", { staticClass: "data-text" }, [
                          _vm._v(_vm._s(_vm.payer.fax || "-")),
                        ]),
                      ]),
                      _c("b-col", [
                        _c("div", { staticClass: "label-text" }, [
                          _vm._v("Billing Email"),
                        ]),
                        _c("div", { staticClass: "data-text" }, [
                          _vm._v(_vm._s(_vm.payer.email || "-")),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c("b-col", [
                        _c("div", { staticClass: "label-text" }, [
                          _vm._v("Claims Portal"),
                        ]),
                        _c("div", { staticClass: "data-text" }, [
                          _vm._v(_vm._s(_vm.payer.claims_portal || "-")),
                        ]),
                      ]),
                      _c("b-col", [
                        _c("div", { staticClass: "label-text" }, [
                          _vm._v("Timely Filing"),
                        ]),
                        _c("div", { staticClass: "data-text" }, [
                          _vm._v(
                            _vm._s(_vm.payer.timely_filing || "-") + " days"
                          ),
                        ]),
                      ]),
                      _c("b-col"),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c("b-col", [
                        _c("div", { staticClass: "label-text" }, [
                          _vm._v("Preauths Portal"),
                        ]),
                        _c("div", { staticClass: "data-text" }, [
                          _vm._v(_vm._s(_vm.payer.preauths_portal || "-")),
                        ]),
                      ]),
                      _c("b-col", [
                        _c("div", { staticClass: "label-text" }, [
                          _vm._v("Preauths Phone"),
                        ]),
                        _c("div", { staticClass: "data-text" }, [
                          _vm._v(_vm._s(_vm.payer.preauths_phone || "-")),
                        ]),
                      ]),
                      _c("b-col", [
                        _c("div", { staticClass: "label-text" }, [
                          _vm._v("Preauths Fax"),
                        ]),
                        _c("div", { staticClass: "data-text" }, [
                          _vm._v(_vm._s(_vm.payer.preauths_fax || "-")),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("payer-source-refs", { attrs: { payer: _vm.payer } }),
              _c(
                "b-overlay",
                { attrs: { show: _vm.chartLoading } },
                [
                  _c(
                    "b-card",
                    { staticClass: "summary-card-table mb-4" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-between px-3 pb-3",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "summary-card-header-txt" },
                            [_vm._v(" Pending Claims ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-end w-50",
                            },
                            [
                              _c("div", { staticClass: "mr-2" }, [
                                _vm._v("By age"),
                              ]),
                              _c("multiselect", {
                                staticClass: "w-50",
                                attrs: {
                                  "hide-selected": true,
                                  options: _vm.ageRangeOptions,
                                  "track-by": "value",
                                  label: "text",
                                  "show-labels": false,
                                  placeholder: "Choose a range",
                                },
                                model: {
                                  value: _vm.pendingAgeRange,
                                  callback: function ($$v) {
                                    _vm.pendingAgeRange = $$v
                                  },
                                  expression: "pendingAgeRange",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("b-table", {
                        ref: "pendingClaimsTable",
                        staticClass: "avo-table-embedded",
                        attrs: {
                          id: "pending-claims-table",
                          items: _vm.getPendingClaims,
                          fields: _vm.pendingClaimsFields,
                          "primary-key": "claim_id",
                          "show-empty": "",
                          "empty-text": "No pending claims",
                          striped: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(claim_id)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _c("strong", [_vm._v(_vm._s(data.value))]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(patient_name)",
                            fn: function (data) {
                              return [
                                _c("div", [_vm._v(_vm._s(data.value) + " ")]),
                              ]
                            },
                          },
                          {
                            key: "cell(provider_name)",
                            fn: function (data) {
                              return [
                                _c("div", [_vm._v(_vm._s(data.value) + " ")]),
                              ]
                            },
                          },
                          {
                            key: "cell(last_action)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formattedFullDate(data.value.created)
                                    ) + " "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(timely_days_left)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(data.value) + " days"),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-overlay",
                { attrs: { show: _vm.chartLoading } },
                [
                  _c(
                    "b-card",
                    { staticClass: "summary-card-table" },
                    [
                      _c("payer-underpayments", {
                        attrs: { payer: _vm.payer },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "5" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "summary-card mb-4",
                  staticStyle: { height: "400px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row justify-content-start" },
                    [
                      _c("div", { staticClass: "summary-card-header-txt" }, [
                        _vm._v(" Age of Claims "),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "chart-container" },
                    [
                      _vm.chartLoading
                        ? _c(
                            "div",
                            { staticClass: "spinner-container" },
                            [_c("b-spinner")],
                            1
                          )
                        : _vm._e(),
                      _c("bar-chart", {
                        attrs: {
                          chartData: _vm.barChartData,
                          styles: { height: "100%", position: "relative" },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-overlay",
                { attrs: { show: _vm.proceduresDataLoading } },
                [
                  _c("b-card", { staticClass: "summary-card mb-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row justify-content-start pb-4",
                      },
                      [
                        _c("div", { staticClass: "summary-card-header-txt" }, [
                          _vm._v(" Top Procedures by Billing "),
                        ]),
                      ]
                    ),
                    _vm.topProcedures.length == 0
                      ? _c("div", { staticStyle: { "text-align": "center" } }, [
                          _vm._v(" No data to display "),
                        ])
                      : _c(
                          "div",
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-between align-items-center mb-3",
                                staticStyle: {
                                  color: "#38455D",
                                  "font-weight": "500",
                                  "font-size": "14px",
                                },
                              },
                              [
                                _c("div", [_vm._v("Procedure")]),
                                _c("div", [_vm._v("Amount")]),
                              ]
                            ),
                            _vm._l(_vm.topProcedures, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "d-flex justify-content-between align-items-center mb-3",
                                },
                                [
                                  _c("div", [_vm._v(_vm._s(item.short))]),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#139F92",
                                        "font-weight": "500",
                                      },
                                    },
                                    [_vm._v("$" + _vm._s(item.count))]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }