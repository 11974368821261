var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0" },
    [
      _c(
        "b-row",
        { staticStyle: { margin: "auto" } },
        [
          _c(
            "b-col",
            { staticStyle: { padding: "0px" } },
            [_c("payer-claim-list")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }