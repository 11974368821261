var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-alert",
        {
          staticStyle: { "margin-left": "20px" },
          attrs: { variant: "danger", dismissible: "" },
          model: {
            value: _vm.showAlert,
            callback: function ($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert",
          },
        },
        [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
      ),
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c("div", { staticClass: "d-flex mb-4" }, [
          _c(
            "div",
            {
              staticClass: "avo-page-header-text",
              staticStyle: { "margin-right": "30px" },
            },
            [_vm._v(" Payers ")]
          ),
          _c(
            "div",
            [
              _c(
                "b-input-group",
                { staticClass: "search-group" },
                [
                  _c("b-input-group-prepend", [
                    _c(
                      "span",
                      { staticClass: "input-group-text" },
                      [_c("b-icon", { attrs: { icon: "search" } })],
                      1
                    ),
                  ]),
                  _c("b-form-input", {
                    staticClass: "payer-search-input pl-1",
                    attrs: {
                      type: "search",
                      placeholder: "Try typing a payer's name",
                    },
                    on: {
                      keyup: _vm.debouncePayers,
                      search: _vm.debouncePayers,
                    },
                    model: {
                      value: _vm.search_params.search,
                      callback: function ($$v) {
                        _vm.$set(_vm.search_params, "search", $$v)
                      },
                      expression: "search_params.search",
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c("span", { staticClass: "divider" }),
                      _c(
                        "b-dropdown",
                        {
                          staticClass: "dropdown type-dropdown",
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " + _vm._s(_vm.typeInputLabel) + " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "b-dropdown-form",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "20px",
                                    "font-weight": "300",
                                    "margin-bottom": "15px",
                                  },
                                },
                                [_vm._v("Types")]
                              ),
                              _c("b-form-checkbox-group", {
                                attrs: { options: _vm.payer_types },
                                on: { change: _vm.debouncePayers },
                                model: {
                                  value: _vm.search_params.payer_types,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.search_params,
                                      "payer_types",
                                      $$v
                                    )
                                  },
                                  expression: "search_params.payer_types",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "divider" }),
                      _c(
                        "b-dropdown",
                        {
                          staticClass: "dropdown filter-dropdown",
                          attrs: { boundary: "window" },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  _c("b-icon-funnel", { staticClass: "mr-2" }),
                                  _vm._v(_vm._s(_vm.filterInputLabel) + " "),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "b-dropdown-form",
                            {
                              staticClass:
                                "d-flex flex-column align-items-start",
                            },
                            [
                              _c("div", { staticClass: "selecter-label" }, [
                                _vm._v("Assigned to"),
                              ]),
                              _c("multiselect", {
                                attrs: {
                                  "hide-selected": true,
                                  options: _vm.workers,
                                  "track-by": "worker_id",
                                  label: "name",
                                  multiple: true,
                                  "show-labels": false,
                                  placeholder: "-- Select an Option --",
                                },
                                model: {
                                  value: _vm.search_params.workers,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search_params, "workers", $$v)
                                  },
                                  expression: "search_params.workers",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end mt-4",
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "avo-basic-btn",
                                      attrs: { variant: "outline-primary" },
                                      on: { click: _vm.clearFilters },
                                    },
                                    [_vm._v("Clear Filters")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "avo-primary-btn ml-3",
                                      attrs: { variant: "outline-primary" },
                                      on: { click: _vm.searchPayers },
                                    },
                                    [_vm._v("Apply")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm.canViewButtons()
          ? _c("div", { staticClass: "d-flex" }, [
              _c(
                "div",
                { staticClass: "mr-3" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.manage-payer-groups",
                          modifiers: { "manage-payer-groups": true },
                        },
                      ],
                      staticClass: "avo-primary-btn mt-2",
                      attrs: { variant: "outline-primary" },
                    },
                    [_vm._v("Manage Payer Groups")]
                  ),
                  _c("payer-manage-groups-modal", {
                    on: {
                      submitted: function ($event) {
                        return _vm.searchPayers()
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.new-payer",
                          modifiers: { "new-payer": true },
                        },
                      ],
                      staticClass: "avo-primary-btn mt-2",
                      attrs: { variant: "outline-primary" },
                    },
                    [_vm._v("Add payer")]
                  ),
                  _c("payer-add", {
                    on: {
                      submitted: function ($event) {
                        return _vm.searchPayers()
                      },
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        [
          _c(
            "div",
            { class: "avo-table-lrg-wrapper" },
            [
              _c("b-table", {
                ref: "payerTable",
                staticClass: "avo-table-lrg",
                attrs: {
                  id: "payers-table",
                  "per-page": _vm.perPage,
                  "current-page": _vm.currentPage,
                  items: _vm.getPayers,
                  fields: _vm.payersFields,
                  selectable: _vm.hasPermission("select_payer"),
                  busy: _vm.tableLoading,
                  "select-mode": "single",
                  "primary-key": "payer_id",
                  "show-empty": "",
                  emptyHtml: _vm.emptyPayerListMessage(),
                  striped: "",
                },
                on: { "row-selected": _vm.setSelectedPayer },
                scopedSlots: _vm._u([
                  {
                    key: "table-busy",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center text-secondary my-2" },
                          [
                            _c("b-spinner", {
                              staticClass: "align-middle mr-2",
                            }),
                            _c("strong", [_vm._v("Fetching payers...")]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "cell(group)",
                    fn: function (data) {
                      return [
                        _c("div", [
                          _c("strong", [_vm._v(_vm._s(data.value || "--"))]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "cell(name)",
                    fn: function (data) {
                      return [
                        _c("div", [
                          _c("strong", [_vm._v(_vm._s(data.value || "--"))]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "cell(state)",
                    fn: function (data) {
                      return [
                        _c("div", [_vm._v(_vm._s(data.value || "--") + " ")]),
                      ]
                    },
                  },
                  {
                    key: "cell(zip)",
                    fn: function (data) {
                      return [
                        _c("div", [_vm._v(_vm._s(data.value || "--") + " ")]),
                      ]
                    },
                  },
                  {
                    key: "cell(full_address)",
                    fn: function (data) {
                      return [
                        _c("div", [_vm._v(_vm._s(data.value || "--") + " ")]),
                      ]
                    },
                  },
                  {
                    key: "cell(telephone)",
                    fn: function (data) {
                      return [
                        _c("div", [_vm._v(_vm._s(data.value || "--") + " ")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.payers.length > 0
            ? _c("b-pagination", {
                attrs: {
                  align: "center",
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  "aria-controls": "payers-table",
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }