var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "add-payer" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "new-payer",
            title: "New Payer",
            "hide-footer": "",
            size: "md",
          },
          on: { hide: _vm.resetModal },
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.showAlert,
                    callback: function ($$v) {
                      _vm.showAlert = $$v
                    },
                    expression: "showAlert",
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
              ),
              _c(
                "div",
                [
                  _c(
                    "div",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "name", label: "Company Name" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                model: {
                                  value: _vm.payer.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payer, "name", $$v)
                                  },
                                  expression: "payer.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "payer_type", label: "Payer Type" } },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.payerTypes },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: null, disabled: "" } },
                                      [_vm._v("Select type")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.payer.payer_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.payer, "payer_type", $$v)
                              },
                              expression: "payer.payer_type",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "timely_filing",
                            label: "Timely Filing",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.payer.timely_filing,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payer, "timely_filing", $$v)
                                  },
                                  expression: "payer.timely_filing",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("b-card", { staticClass: "mt-4 mb-4" }, [
                    _c("div", { staticClass: "avo-header-text" }, [
                      _vm._v(" Address and Contact Info "),
                    ]),
                    _c("hr", {
                      staticStyle: {
                        "margin-right": "-20px",
                        "margin-left": "-20px",
                      },
                    }),
                    _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          { attrs: { id: "address_line1", label: "Address" } },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.payer_address.address_line1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.payer_address,
                                        "address_line1",
                                        $$v
                                      )
                                    },
                                    expression: "payer_address.address_line1",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "mr-2" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { id: "city", label: "City" } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    model: {
                                      value: _vm.payer_address.city,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.payer_address, "city", $$v)
                                      },
                                      expression: "payer_address.city",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mr-2 ml-2" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { id: "state", label: "State" } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    model: {
                                      value: _vm.payer_address.state,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payer_address,
                                          "state",
                                          $$v
                                        )
                                      },
                                      expression: "payer_address.state",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-2" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { id: "zip", label: "Zip" } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    model: {
                                      value: _vm.payer_address.zip,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.payer_address, "zip", $$v)
                                      },
                                      expression: "payer_address.zip",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "mr-2" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { id: "fax", label: "Fax" } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    model: {
                                      value: _vm.payer_address.fax,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.payer_address, "fax", $$v)
                                      },
                                      expression: "payer_address.fax",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-2" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { id: "telephone", label: "Claim Phone" },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    model: {
                                      value: _vm.payer_address.telephone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payer_address,
                                          "telephone",
                                          $$v
                                        )
                                      },
                                      expression: "payer_address.telephone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          { attrs: { id: "email", label: "Billing Email" } },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.payer_address.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.payer_address, "email", $$v)
                                    },
                                    expression: "payer_address.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          { attrs: { id: "website", label: "Website" } },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.payer_address.website,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.payer_address,
                                        "website",
                                        $$v
                                      )
                                    },
                                    expression: "payer_address.website",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "mt-4",
                      staticStyle: { "text-align": "right" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn",
                          on: { click: _vm.validatePayerInputs },
                        },
                        [_vm._v(" Create Payer ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }