var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex flex-row justify-content-between px-3 pb-3" },
        [
          _c("div", { staticClass: "summary-card-header-txt" }, [
            _vm._v("Under Payments"),
          ]),
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center justify-content-end w-50",
            },
            [
              _c("div", { staticClass: "mr-2" }, [_vm._v("By age")]),
              _c("multiselect", {
                staticClass: "w-50",
                attrs: {
                  "hide-selected": true,
                  options: _vm.ageRangeOptions,
                  "track-by": "value",
                  label: "text",
                  "show-labels": false,
                  placeholder: "Choose a range",
                },
                model: {
                  value: _vm.underpaidAgeRange,
                  callback: function ($$v) {
                    _vm.underpaidAgeRange = $$v
                  },
                  expression: "underpaidAgeRange",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-table",
        {
          ref: "underpaidClaimsTable",
          staticClass: "avo-table-embedded",
          attrs: {
            id: "underpaid-claims-table",
            items: _vm.getUnderpaidClaims,
            "no-provider-paging": "",
            fields: _vm.underPaymentsFields,
            "per-page": _vm.underPaymentsPerPage,
            "current-page": _vm.underPaymentsPage,
            "primary-key": "claim_id",
            "show-empty": "",
            "empty-text": "No underpayments",
            striped: "",
          },
          scopedSlots: _vm._u([
            {
              key: "cell(claim_id)",
              fn: function (data) {
                return [_c("div", [_c("strong", [_vm._v(_vm._s(data.value))])])]
              },
            },
            {
              key: "cell(balance)",
              fn: function (data) {
                return [
                  _c("div", [_vm._v(_vm._s(_vm.formatDollars(data.value)))]),
                ]
              },
            },
            {
              key: "cell(provider_name)",
              fn: function (data) {
                return [_c("div", [_vm._v(_vm._s(data.value))])]
              },
            },
            {
              key: "cell(last_payer_payment_date)",
              fn: function (data) {
                return [
                  _c("div", [_vm._v(_vm._s(_vm.formatFullDate(data.value)))]),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "bottom-row" }, [
            _vm.showUnderPaymentsLimitWarning
              ? _c(
                  "td",
                  {
                    staticStyle: { "text-align": "center" },
                    attrs: { colspan: "99" },
                  },
                  [
                    _vm._v(
                      " Number of results limited to " +
                        _vm._s(_vm.underPaidMaxResults) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c(
        "div",
        [
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.underPaymentsTotalCount,
              "per-page": _vm.underPaymentsPerPage,
              align: "center",
            },
            model: {
              value: _vm.underPaymentsPage,
              callback: function ($$v) {
                _vm.underPaymentsPage = $$v
              },
              expression: "underPaymentsPage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }