var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.payer
    ? _c(
        "b-container",
        { staticClass: "p-0" },
        [
          !_vm.modalAlert
            ? _c(
                "b-alert",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.showAlert,
                    callback: function ($$v) {
                      _vm.showAlert = $$v
                    },
                    expression: "showAlert",
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
              )
            : _vm._e(),
          _c(
            "b-alert",
            {
              attrs: { variant: "success", dismissible: "" },
              model: {
                value: _vm.showSuccess,
                callback: function ($$v) {
                  _vm.showSuccess = $$v
                },
                expression: "showSuccess",
              },
            },
            [_c("b", [_vm._v("Copied to clipboard")])]
          ),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between",
              staticStyle: { "margin-left": "20px" },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "b-input-group",
                    { staticClass: "search-group" },
                    [
                      _c("b-input-group-prepend", [
                        _c(
                          "span",
                          { staticClass: "input-group-text" },
                          [_c("b-icon", { attrs: { icon: "search" } })],
                          1
                        ),
                      ]),
                      _c("b-form-input", {
                        staticClass: "resource-search-input pl-1",
                        attrs: {
                          type: "search",
                          placeholder: "Search resources",
                        },
                        on: {
                          keyup: _vm.debounceResources,
                          search: _vm.debounceResources,
                        },
                        model: {
                          value: _vm.search_params.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.search_params, "name", $$v)
                          },
                          expression: "search_params.name",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c("span", { staticClass: "divider" }),
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "dropdown filter-dropdown",
                              attrs: { boundary: "window" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c("b-icon-funnel", {
                                          staticClass: "mr-2",
                                        }),
                                        _vm._v(
                                          _vm._s(_vm.filterInputLabel) + " "
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2411036029
                              ),
                            },
                            [
                              _c(
                                "b-dropdown-form",
                                {
                                  staticClass:
                                    "d-flex flex-column align-items-start",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "20px",
                                        "font-weight": "300",
                                      },
                                    },
                                    [_vm._v("Filters")]
                                  ),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Type"),
                                  ]),
                                  _c("multiselect", {
                                    attrs: {
                                      "hide-selected": true,
                                      options: _vm.types,
                                      "track-by": "type",
                                      label: "name",
                                      multiple: true,
                                      "show-labels": false,
                                      placeholder: "-- Select an Option --",
                                    },
                                    model: {
                                      value: _vm.filters.types,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "types", $$v)
                                      },
                                      expression: "filters.types",
                                    },
                                  }),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Status"),
                                  ]),
                                  _c("multiselect", {
                                    attrs: {
                                      "hide-selected": true,
                                      options: _vm.flags,
                                      "track-by": "flag",
                                      label: "name",
                                      multiple: true,
                                      "show-labels": false,
                                      placeholder: "-- Select an Option --",
                                    },
                                    model: {
                                      value: _vm.filters.flags,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "flags", $$v)
                                      },
                                      expression: "filters.flags",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-start mt-4",
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "avo-primary-btn mr-5",
                                          attrs: { variant: "outline-primary" },
                                          on: { click: _vm.doSearch },
                                        },
                                        [_vm._v("Apply")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "avo-basic-btn",
                                          attrs: { variant: "outline-primary" },
                                          on: { click: _vm.clearFilters },
                                        },
                                        [_vm._v("Clear Filters")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-right": "20px" } },
                [
                  _vm.hasPermission("edit")
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.new-payer-resource",
                              modifiers: { "new-payer-resource": true },
                            },
                          ],
                          staticClass: "avo-primary-btn",
                        },
                        [_vm._v("Add Login")]
                      )
                    : _vm._e(),
                  _c("payer-resource-add", {
                    on: {
                      submitted: function ($event) {
                        return _vm.getResources()
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.resources.length > 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-left": "20px",
                    "margin-right": "20px",
                  },
                },
                [
                  _c(
                    "b-table-simple",
                    {
                      staticClass: "resource-table mt-4",
                      attrs: { borderless: "" },
                    },
                    [
                      _c(
                        "b-thead",
                        { staticStyle: { "font-size": "14px !important" } },
                        [
                          _c(
                            "b-tr",
                            [
                              _c("b-th", [_vm._v(" Active ")]),
                              _c("b-th", [_vm._v(" Practice ")]),
                              _c("b-th", [_vm._v(" Name ")]),
                              _c("b-th", [_vm._v(" Username ")]),
                              _c("b-th", [_vm._v(" Password ")]),
                              _c("b-th", [_vm._v(" Portal ")]),
                              _c("b-th", [_vm._v(" Updated ")]),
                              _c("b-th"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tbody",
                        { staticStyle: { "font-size": "14px !important" } },
                        _vm._l(_vm.resources, function (resource, index) {
                          return _c(
                            "b-tr",
                            { key: index },
                            [
                              _c(
                                "b-td",
                                [
                                  _c(
                                    "b-icon",
                                    _vm._b(
                                      {},
                                      "b-icon",
                                      _vm.activeIcon(_vm.active[index]),
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                              _c("b-td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      resource.practice
                                        ? resource.practice.name
                                        : "N/A"
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(" " + _vm._s(resource.name) + " "),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  " " + _vm._s(resource.username || "--") + " "
                                ),
                              ]),
                              _c(
                                "b-td",
                                [
                                  _c("b-icon", {
                                    staticClass: "mr-2",
                                    attrs: { icon: "clipboard" },
                                  }),
                                  _c(
                                    "u",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.askToCopyPassword(index)
                                        },
                                      },
                                    },
                                    [_vm._v("Copy password")]
                                  ),
                                  _vm.masterPasswordNeeded(index)
                                    ? _c("b-icon", {
                                        staticClass: "ml-2",
                                        attrs: { icon: "lock" },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "b-modal",
                                    {
                                      attrs: {
                                        title: "Enter master password",
                                        id: "password-modal-" + index,
                                        "hide-footer": "",
                                      },
                                      on: {
                                        hide: function ($event) {
                                          return _vm.clearPasswordModalData()
                                        },
                                      },
                                    },
                                    [
                                      _vm.modalAlert
                                        ? _c(
                                            "b-alert",
                                            {
                                              staticStyle: {
                                                "margin-left": "20px",
                                              },
                                              attrs: {
                                                variant: "danger",
                                                dismissible: "",
                                              },
                                              model: {
                                                value: _vm.showAlert,
                                                callback: function ($$v) {
                                                  _vm.showAlert = $$v
                                                },
                                                expression: "showAlert",
                                              },
                                            },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.alertMessage)
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "avo-header-text",
                                          staticStyle: { "text-align": "left" },
                                        },
                                        [
                                          _vm._v(
                                            " Practice master resource password "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-4 mr-4 ml-4" },
                                        [
                                          _c("b-form-input", {
                                            staticStyle: {
                                              "text-security": "disc",
                                              "-webkit-text-security": "disc",
                                            },
                                            model: {
                                              value: _vm.user_input,
                                              callback: function ($$v) {
                                                _vm.user_input = $$v
                                              },
                                              expression: "user_input",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-4",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "avo-primary-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.getPassword(index)
                                                },
                                              },
                                            },
                                            [_vm._v("Submit password")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-td",
                                {},
                                [
                                  resource.url
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "avo-basic-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.loginPortal(
                                                resource.url
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Login")]
                                      )
                                    : _c("div", [_vm._v("--")]),
                                ],
                                1
                              ),
                              _c("b-td", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatDate(resource.updated)) +
                                    " "
                                ),
                              ]),
                              _c(
                                "b-td",
                                [
                                  _c(
                                    "b-dropdown",
                                    {
                                      staticClass: "resource-dropdown",
                                      staticStyle: { "min-width": "none" },
                                      attrs: { "no-caret": "", right: true },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "button-content",
                                            fn: function () {
                                              return [
                                                _c("b-icon", {
                                                  attrs: { icon: "three-dots" },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewRecoveryModal(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("View Recovery Info")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.editResource(index)
                                            },
                                          },
                                        },
                                        [_vm._v("Edit")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.archiveResource(index)
                                            },
                                          },
                                        },
                                        [_vm._v("Archive")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-modal",
                                    {
                                      attrs: {
                                        id: "recovery-modal-" + index,
                                        "hide-footer": "",
                                        "hide-header": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "avo-header-text",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Recovery Information for " +
                                              _vm._s(resource.name) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("hr"),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-4",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [_vm._v(" Resource Admin ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-2 bold",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(resource.admin || "N/A") +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-4",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [_vm._v(" Email Listed ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-2 bold",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(resource.email || "N/A") +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-4",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [_vm._v(" 2FA Phone #: ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-2 bold",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                resource.telephone || "N/A"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-4",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [_vm._v(" Security Question Answers: ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-2 bold",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                resource.security_question_answers ||
                                                  "N/A"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-4",
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "avo-basic-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.closeRecoveryModal(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Close")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPage,
                          align: "center",
                        },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("payer-resource-edit", {
                    on: {
                      submitted: function ($event) {
                        return _vm.getResources()
                      },
                    },
                  }),
                ],
                1
              )
            : _vm.loaded
            ? _c("div", { staticStyle: { "text-align": "center" } }, [
                _vm._v(" No login information found for this payer "),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }