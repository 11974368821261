var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "modify-payer-resource" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "edit-payer-resource",
            title: "Edit Payer Login",
            "hide-footer": "",
            size: "lg",
          },
          on: { hide: _vm.resetModal },
        },
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.showAlert,
                    callback: function ($$v) {
                      _vm.showAlert = $$v
                    },
                    expression: "showAlert",
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
              ),
              _vm.master_password_needed
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "master_password",
                                label: "Master password:",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    staticStyle: {
                                      "text-security": "disc",
                                      "-webkit-text-security": "disc",
                                    },
                                    model: {
                                      value: _vm.form.master_password,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "master_password",
                                          $$v
                                        )
                                      },
                                      expression: "form.master_password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "name", label: "Name:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "url", label: "URL:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                model: {
                                  value: _vm.form.url,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "url", $$v)
                                  },
                                  expression: "form.url",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "flag", label: "Flag:" } },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.flags },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: null, disabled: "" } },
                                      [_vm._v("-- Please select an option --")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.form.flag,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "flag", $$v)
                              },
                              expression: "form.flag",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "type", label: "Type:" } },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.types },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: null, disabled: "" } },
                                      [_vm._v("-- Please select an option --")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.form.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "type", $$v)
                              },
                              expression: "form.type",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "username", label: "Username:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                model: {
                                  value: _vm.form.username,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "username", $$v)
                                  },
                                  expression: "form.username",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "password", label: "New Password:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "password",
                                  autocomplete: "new-password",
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "confirmpassword",
                            label: "Confirm new password:",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: { type: "password" },
                                model: {
                                  value: _vm.form.confirm_password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "confirm_password", $$v)
                                  },
                                  expression: "form.confirm_password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "email", label: "Email:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                model: {
                                  value: _vm.form.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "admin", label: "Admin:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                model: {
                                  value: _vm.form.admin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "admin", $$v)
                                  },
                                  expression: "form.admin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "telephone", label: "2FA Phone #:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                model: {
                                  value: _vm.form.telephone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "telephone", $$v)
                                  },
                                  expression: "form.telephone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "security_question_answers",
                            label: "Security Question Answers:",
                          },
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              placeholder:
                                "Enter security questions with answers",
                            },
                            model: {
                              value: _vm.form.security_question_answers,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "security_question_answers",
                                  $$v
                                )
                              },
                              expression: "form.security_question_answers",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.hasPermission("assign_resources")
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "selected_practice",
                                label: "Assign to a Practice",
                              },
                            },
                            [
                              _c("practice-multiselect", {
                                attrs: { multiple: false },
                                model: {
                                  value: _vm.selected_practice,
                                  callback: function ($$v) {
                                    _vm.selected_practice = $$v
                                  },
                                  expression: "selected_practice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasPermission("assign_resources")
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "selected_workers",
                                label: "Assign To:",
                              },
                            },
                            [
                              _c("worker-multiselect", {
                                attrs: { multiple: true },
                                on: {
                                  select: function ($event) {
                                    _vm.new_workers = true
                                  },
                                  remove: function ($event) {
                                    _vm.new_workers = true
                                  },
                                },
                                model: {
                                  value: _vm.selected_workers,
                                  callback: function ($$v) {
                                    _vm.selected_workers = $$v
                                  },
                                  expression: "selected_workers",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn",
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" Submit Login Changes ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }