var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.payer
    ? _c(
        "div",
        [
          _c(
            "div",
            [
              _c(
                "back-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.clearSelectedPayer()
                    },
                  },
                },
                [_vm._v("Back to Payers")]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "b-alert",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.showAlert,
                    callback: function ($$v) {
                      _vm.showAlert = $$v
                    },
                    expression: "showAlert",
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4 ml-2 mr-4" },
            [
              _c(
                "b-col",
                { staticClass: "d-flex flex-column", attrs: { cols: "6" } },
                [
                  _c("div", { staticClass: "avo-text-light" }, [
                    _vm._v(" Payer "),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "avo-header-text",
                      staticStyle: { "font-size": "36px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.payer.name) + " ")]
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center",
                  attrs: { cols: "4" },
                },
                [
                  _c("div", {
                    staticClass: "mt-2 mr-2",
                    staticStyle: {
                      "padding-left": "10px",
                      "border-left": "4px solid var(--blue)",
                      "margin-bottom": "13px",
                    },
                  }),
                  _c("div", { staticClass: "d-flex flex-column mr-4" }, [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "avo-header-text bold",
                          staticStyle: { "font-size": "28px" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.payerSummaryLoading ? "-" : _vm.num_pending
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("div", [_vm._v(" Pending Claims ")]),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "mt-2 ml-4 mr-2",
                    staticStyle: {
                      "padding-left": "10px",
                      "border-left": "4px solid #FD4739",
                      "margin-bottom": "13px",
                    },
                  }),
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c(
                      "div",
                      {
                        staticClass: "avo-header-text bold",
                        staticStyle: { "font-size": "28px" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.payerSummaryLoading ? "-" : _vm.num_underpaid
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("div", [_vm._v(" Under Payments ")]),
                  ]),
                  _c("div", {
                    staticClass: "mt-2 ml-4 mr-2",
                    staticStyle: {
                      "padding-left": "10px",
                      "border-left": "4px solid #41B0A5",
                      "margin-bottom": "13px",
                    },
                  }),
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c(
                      "div",
                      {
                        staticClass: "avo-header-text bold",
                        staticStyle: { "font-size": "28px" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.payerSummaryLoading ? "-" : _vm.num_issues
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("div", [_vm._v(" Issues ")]),
                  ]),
                ]
              ),
              _c("b-col", { staticClass: "d-flex justify-content-end" }, [
                _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.payer-edit",
                            modifiers: { "payer-edit": true },
                          },
                        ],
                        staticClass: "avo-primary-btn",
                        attrs: { disabled: _vm.editPayerLoading },
                      },
                      [
                        _c(
                          "b-overlay",
                          {
                            attrs: {
                              show: _vm.editPayerLoading,
                              variant: "white",
                            },
                          },
                          [_vm._v("Edit Payer")]
                        ),
                      ],
                      1
                    ),
                    _c("payer-edit", {
                      on: {
                        loaded: function ($event) {
                          _vm.editPayerLoading = false
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "b-nav",
                { staticClass: "nav-section", attrs: { tabs: "" } },
                [
                  _vm.hasPermission("summary_tab")
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: { active: _vm.activeTab == "PayerSummary" },
                          on: {
                            click: function ($event) {
                              return _vm.setActiveTab("PayerSummary")
                            },
                          },
                        },
                        [_vm._v("Summary")]
                      )
                    : _vm._e(),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.activeTab == "PayerClaims" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("PayerClaims")
                        },
                      },
                    },
                    [_vm._v("Claims")]
                  ),
                  _vm.hasPermission("resources_tab")
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            active: _vm.activeTab == "PayerResourceLogins",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setActiveTab("PayerResourceLogins")
                            },
                          },
                        },
                        [_vm._v("Logins")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "keep-alive",
                [_c(_vm.activeTab, { tag: "component", staticClass: "mt-4" })],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }