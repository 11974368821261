var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "avo-info-card mb-4" },
    [
      _c("div", { staticClass: "avo-header-text mb-4" }, [
        _vm._v("Source Aliases"),
      ]),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", [
            _c("div", { staticClass: "label-text mb-3" }, [
              _vm._v(" eClinicalWorks Insurance IDs "),
            ]),
            _c(
              "div",
              { staticClass: "data-text-large" },
              [
                _vm._l(_vm.ecw_source_refs, function (source_ref) {
                  return _c(
                    "b-badge",
                    {
                      key: source_ref.source_ref + "-" + source_ref.practice_id,
                      staticClass:
                        "mr-2 p-2 mb-2 border border-secondary border-1 text-secondary",
                      attrs: { pill: "", href: "#!", variant: "light" },
                      on: {
                        click: function ($event) {
                          return _vm.removePayerSourceRef(source_ref, "ecw")
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(source_ref.source_ref) +
                          " (" +
                          _vm._s(source_ref.practice_id) +
                          ") "
                      ),
                      _c("b-icon", {
                        staticClass: "ml-1",
                        attrs: { icon: "x-circle-fill", "v-if": _vm.canEdit },
                      }),
                    ],
                    1
                  )
                }),
                _vm.ecw_source_refs.length < 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-overlay",
                          { attrs: { show: _vm.source_refs_loading } },
                          [_vm._v(" No eCW Insurance IDs ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("br"),
                _c(
                  "b-badge",
                  {
                    staticClass:
                      "mr-2 text-light border border-primary border-1 p-2 mt-1",
                    attrs: {
                      pill: "",
                      href: "#!",
                      variant: "primary",
                      "v-if": _vm.canEdit,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.showAddSourceRefModal("ecw")
                      },
                    },
                  },
                  [
                    _c("b-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "plus-circle-fill" },
                    }),
                    _vm._v(" Add eCW insID "),
                  ],
                  1
                ),
              ],
              2
            ),
          ]),
          _c("b-col", [
            _c("div", { staticClass: "label-text mb-3" }, [
              _vm._v(" Allscripts Carrier IDs "),
            ]),
            _c(
              "div",
              { staticClass: "data-text-large" },
              [
                _vm._l(_vm.allscripts_source_refs, function (source_ref) {
                  return _c(
                    "b-badge",
                    {
                      key: source_ref.source_ref + "-" + source_ref.practice_id,
                      staticClass:
                        "mr-2 p-2 mb-2 border border-secondary text-secondary",
                      attrs: { pill: "", href: "#!", variant: "light" },
                      on: {
                        click: function ($event) {
                          return _vm.removePayerSourceRef(
                            source_ref,
                            "allscripts"
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(source_ref.source_ref) +
                          " (" +
                          _vm._s(source_ref.practice_id) +
                          ") "
                      ),
                      _c("b-icon", {
                        staticClass: "ml-1",
                        attrs: { icon: "x-circle-fill", "v-if": _vm.canEdit },
                      }),
                    ],
                    1
                  )
                }),
                _vm.allscripts_source_refs.length < 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-overlay",
                          { attrs: { show: _vm.source_refs_loading } },
                          [_vm._v(" No Allscripts Carrier IDs ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("br"),
                _c(
                  "b-badge",
                  {
                    staticClass:
                      "mr-2 text-light border border-primary p-2 mt-1",
                    attrs: {
                      pill: "",
                      variant: "primary",
                      href: "#!",
                      "v-if": _vm.canEdit,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.showAddSourceRefModal("allscripts")
                      },
                    },
                  },
                  [
                    _c("b-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "plus-circle-fill" },
                    }),
                    _vm._v(" Add Allscripts CarrierID "),
                  ],
                  1
                ),
              ],
              2
            ),
          ]),
          _c(
            "b-modal",
            {
              attrs: {
                id: "add-source-ref-modal",
                "hide-footer": "",
                "hide-header": "",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "avo-header-text",
                  staticStyle: { "text-align": "center" },
                },
                [_vm._v(" Add new source reference ")]
              ),
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.showAlertModal,
                    callback: function ($$v) {
                      _vm.showAlertModal = $$v
                    },
                    expression: "showAlertModal",
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.errorTextModal))])]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Practice",
                    "label-for": "practice_id",
                    state: !_vm.new_source_ref_do_validate
                      ? null
                      : _vm.new_source_ref.practice != null,
                    "invalid-feedback": "Please select a practice",
                    description:
                      "Select a primary practice for import association, may also be used for other practices on the same external PM system",
                  },
                },
                [
                  _c("practice-multiselect", {
                    attrs: {
                      id: "practice_id",
                      closeOnSelect: true,
                      multiple: false,
                      state: !_vm.new_source_ref_do_validate
                        ? null
                        : _vm.new_source_ref.practice != null,
                    },
                    model: {
                      value: _vm.new_source_ref.practice,
                      callback: function ($$v) {
                        _vm.$set(_vm.new_source_ref, "practice", $$v)
                      },
                      expression: "new_source_ref.practice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Source Reference ID",
                    "label-for": "source_ref",
                    state: !_vm.new_source_ref_do_validate
                      ? null
                      : _vm.new_source_ref.source_ref != null,
                    "invalid-feedback":
                      "Please enter a data source reference/alias ID",
                    description:
                      "Carrier ID or Insurance ID of payer in external PM system",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "source_ref",
                      placeholder: "e.g. 12345",
                      type: "number",
                      required: "",
                      state: !_vm.new_source_ref_do_validate
                        ? null
                        : _vm.new_source_ref.source_ref != null,
                    },
                    model: {
                      value: _vm.new_source_ref.source_ref,
                      callback: function ($$v) {
                        _vm.$set(_vm.new_source_ref, "source_ref", $$v)
                      },
                      expression: "new_source_ref.source_ref",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end mt-4" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-basic-btn",
                      on: { click: _vm.hideAddSourceRefModal },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-primary-btn ml-2",
                      on: {
                        click: function ($event) {
                          return _vm.addPayerSourceRef()
                        },
                      },
                    },
                    [_vm._v(" Add ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }